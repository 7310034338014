import { qs, qsa } from '../../resources/js/utils/dom'
import Swiper from "swiper";
import { Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css';

const postsSlider = new Swiper('.posts-featured-container', {
	modules: [Navigation, Autoplay],
    slidesPerView: "auto",
    slidesPerGroup: 1,
    loopAddBlankSlides: true,
    loop: true,
    speed: 1000,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
});

window.addEventListener('load', () => {
	postsSlider.init()
});

window.addEventListener('resize', () => {
	postsSlider.update()
});

window.addEventListener('click', (event) => {
	if (event.target.matches('.posts-featured--filter')) {
		event.preventDefault();
		for (const item of qsa("[data-filter]")) {
			item.classList.remove('filter-visible');
		}

		qs(".posts-featured--filters a.active").classList.remove('active');
		event.target.classList.add('active');

		for (const item of qsa("[data-filter=" + CSS.escape(event.target.id) + "]")) {
			item.classList.add('filter-visible');
		}
		postsSlider.update()
	}

	if (event.target.matches('.posts-featured--filter-all')) {
		event.preventDefault();
		qs(".posts-featured--filters a.active").classList.remove('active');
		event.target.classList.add('active');

		for (const item of qsa("[data-filter]")) {
			item.classList.add('filter-visible');
		}
		postsSlider.update()
	}
});
